import { protectedAxios } from "./helpers";
import { format } from 'date-fns'

export class MessageService {
  static messages(quota_id) {
    return protectedAxios.get(`/messages/`, {
      params: { quota_id }
    });
  }
  static createMessage({ phone, message, quota_id, project_id }) {
    // TODO: create_date waiting from API
    return protectedAxios.post(`/messages/`, { phone, message, quota_id, project_id, create_date: format(new Date(), 'yyyy-MM-dd') });
  }
  static updateMessage(id, { phone, message }) {
    return protectedAxios.put(`/messages/${id}`, { phone, message });
  }
}